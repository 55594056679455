import { useRequest } from '@/composables/useRequest';
import { API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { FORMAT_RESPONSE } from '@/constants/common.const';
import { DEFAULT_COUNTRY } from '@/constants/cookie.const';
import { ApiMethod } from '@/enums/api.enum';
import type { BusinessReviewStatus } from '@/enums/business.enum';
import { fetchBankListMapper } from '@/mappers/bank.mapper';
import { fetchBusinessListMapper } from '@/mappers/business.mapper';
import type { BankListModel } from '@/types/bank-info/bank-model.type';
import type { BankInfoRequest, CheckBankInfoRequest } from '@/types/bank-info/bank-request.type';
import type {
  BankInfoResponse,
  BankListResponse,
  KrBankListResponse
} from '@/types/bank-info/bank-response.type';
import type {
  BusinessListModel,
  PreviewCheckModel
} from '@/types/business-info/business-model.type';
import type { BusinessInfoRequest } from '@/types/business-info/business-request.type';
import type {
  BusinessInfoItem,
  BusinessInfoResponse,
  BusinessListResponse,
  CheckBusinessRegistrationNumberResponse,
  DistrictsResponse,
  ProvincesResponse
} from '@/types/business-info/business-response.type';
import type { BusinessBankContract } from '@/types/common/file.type';
import { camelToSnake, generateHeader } from '@/utils/api.util';
import { getConfigs } from '@/utils/common.util';
import { setMultiFileFormData } from '@/utils/file.util';

const CONTEXT = 'business-bank-info/group';

// Wiki: https://wiki.smilegate.net/pages/viewpage.action?pageId=499437552
export const fetchCompanyStatusApi = async (
  groupId: string
): Promise<BusinessReviewStatus | undefined> => {
  const { data } = await useRequest<{ status: BusinessReviewStatus }>(
    `${STUDIO_API_URL}/${API_VERSION}/${CONTEXT}/${groupId}/company-status`,
    {
      showCommonError: false
    }
  );

  return data?.status;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=496214394
export const fetchBankStatusApi = async (
  groupId: string
): Promise<BusinessReviewStatus | undefined> => {
  const { data } = await useRequest<{ status: BusinessReviewStatus }>(
    `${STUDIO_API_URL}/${API_VERSION}/${CONTEXT}/${groupId}/bank-status`,
    {
      showCommonError: false
    }
  );

  return data?.status;
};

// Business Info
// Wiki: https://wiki.smilegate.net/pages/viewpage.action?pageId=435947650
export const fetchBusinessInfosApi = async (
  groupId: string,
  showCommonError: boolean = true
): Promise<BusinessInfoResponse | undefined> => {
  const { data } = await useRequest<BusinessInfoResponse>(
    `${STUDIO_API_URL}/${API_VERSION}/${CONTEXT}/${groupId}/company/info`,
    {
      showCommonError
    }
  );

  return data;
};

// Wiki: https://wiki.smilegate.net/pages/viewpage.action?pageId=435948154
export const fetchBusinessHistoriesApi = async (
  groupId: string
): Promise<BusinessListModel | undefined> => {
  const { data } = await useRequest<BusinessListModel, BusinessListResponse>(
    `${STUDIO_API_URL}/${API_VERSION}/${CONTEXT}/${groupId}/company/approval/histories`,
    {},
    fetchBusinessListMapper
  );

  return data;
};

// Wiki: https://wiki.smilegate.net/pages/viewpage.action?pageId=435955221 & https://wiki.smilegate.net/pages/viewpage.action?pageId=435955343
export const createUpdateBusinessInfoApi = async (
  groupId: string,
  params: BusinessInfoRequest,
  isEdit: boolean = false
): Promise<BusinessInfoItem | undefined> => {
  const url = `${STUDIO_API_URL}/${API_VERSION}/${CONTEXT}/${groupId}/company/info`;

  const { data } = await useRequest<BusinessInfoItem>(url, {
    method: !isEdit ? ApiMethod.Post : ApiMethod.Put,
    data: camelToSnake(params),
    showCommonError: false
  });

  return data;
};

// Wiki: https://wiki.smilegate.net/pages/viewpage.action?pageId=450826814&
export const previewCheckBusinessInfoApi = async (
  groupId: string
): Promise<PreviewCheckModel | undefined> => {
  const { data } = await useRequest<PreviewCheckModel>(
    `${STUDIO_API_URL}/${API_VERSION}/${CONTEXT}/${groupId}/company/preview-check`,
    {
      method: ApiMethod.Put,
      headers: { ...generateHeader('X-Lang', 'X-Nation') }
    }
  );

  return data;
};

// Bank info
// Wiki: https://wiki.smilegate.net/pages/viewpage.action?pageId=435971219
export const fetchBankInfosApi = async (groupId: string): Promise<BankInfoResponse | undefined> => {
  const { data } = await useRequest<BankInfoResponse>(
    `${STUDIO_API_URL}/${API_VERSION}/${CONTEXT}/${groupId}/bank/info`
  );

  return data;
};

// Wiki: https://wiki.smilegate.net/pages/viewpage.action?pageId=435956930
export const fetchBankHistoriesApi = async (
  groupId: string
): Promise<BankListModel | undefined> => {
  const { data } = await useRequest<BankListModel, BankListResponse>(
    `${STUDIO_API_URL}/${API_VERSION}/${CONTEXT}/${groupId}/bank/approval/histories`,
    {},
    fetchBankListMapper
  );

  return data;
};

// Wiki: https://wiki.smilegate.net/pages/viewpage.action?pageId=435955534 & https://wiki.smilegate.net/pages/viewpage.action?pageId=435956922
export const createUpdateBankInfoApi = async (
  groupId: string,
  params: BankInfoRequest,
  isEdit: boolean = false
): Promise<BankInfoResponse | undefined> => {
  const { data } = await useRequest<BankInfoResponse>(
    `${STUDIO_API_URL}/${API_VERSION}/${CONTEXT}/${groupId}/bank/info`,
    {
      method: !isEdit ? ApiMethod.Post : ApiMethod.Put,
      data: camelToSnake(params),
      showCommonError: false
    }
  );

  return data;
};

// Contract Api
// Wiki: https://wiki.smilegate.net/pages/viewpage.action?pageId=435971747
export const uploadContractApi = async (
  groupId: string,
  files: File[]
): Promise<BusinessBankContract | undefined> => {
  const { APPLICATION_NO } = getConfigs();

  const formData = setMultiFileFormData({
    applicationNo: APPLICATION_NO,
    files: [],
    category: 'contract'
  });

  if (formData) {
    formData.append('file', files[0]);
  }

  if (!formData) {
    return;
  }

  const { data } = await useRequest<BusinessBankContract>(
    `${STUDIO_API_URL}/${API_VERSION}/${CONTEXT}/${groupId}/contract/upload`,
    {
      method: ApiMethod.Post,
      data: formData,
      cancelAfter: 0
    }
  );

  return data;
};

// Wiki: https://wiki.smilegate.net/pages/viewpage.action?pageId=499437566
export const downloadContractApi = async (
  groupId: string,
  contractNo: number
): Promise<Blob | undefined> => {
  const res = await useRequest<Blob>(
    `${STUDIO_API_URL}/${API_VERSION}/${CONTEXT}/${groupId}/contract/${contractNo}/download`,
    {
      cancelAfter: 0,
      responseType: 'blob',
      formatResponse: FORMAT_RESPONSE.CUSTOM
    }
  );
  return res.data;
};

// Check Valid Business Registration License
// Wiki: https://wiki.smilegate.net/pages/viewpage.action?pageId=435956381
export const checkBusinessRegistrationLicenseApi = async (
  groupId: string,
  licenseNumber: string
): Promise<CheckBusinessRegistrationNumberResponse | undefined> => {
  const { data } = await useRequest<CheckBusinessRegistrationNumberResponse>(
    `${STUDIO_API_URL}/${API_VERSION}/${CONTEXT}/${groupId}/company-inquire?nation=KR&business_registration_no=${licenseNumber}`,
    {
      showCommonError: false
    }
  );

  return data;
};

// Get Korea Bank List
// Wiki: https://wiki.smilegate.net/pages/viewpage.action?pageId=440995751
export const fetchKrBanksApi = async (
  nation: string = DEFAULT_COUNTRY
): Promise<KrBankListResponse | undefined> => {
  const { data } = await useRequest<KrBankListResponse>(
    `${STUDIO_API_URL}/${API_VERSION}/business-bank-info/banks?nation=${nation}`,
    {
      headers: { ...generateHeader('X-Lang') }
    }
  );

  return data;
};

// check Kr Bank Info
// Wiki: https://wiki.smilegate.net/pages/viewpage.action?pageId=435956492
export const checkKrBankInfoApi = async (
  groupId: string,
  params: CheckBankInfoRequest
): Promise<BankInfoResponse | undefined> => {
  const { data } = await useRequest<BankInfoResponse>(
    `${STUDIO_API_URL}/${API_VERSION}/${CONTEXT}/${groupId}/bank-inquire`,
    {
      showCommonError: false,
      params: camelToSnake(params),
      cancelAfter: 0
    }
  );

  return data;
};

// Wiki: https://wiki.smilegate.net/pages/viewpage.action?pageId=450826835
export const previewCheckBankInfoApi = async (
  groupId: string
): Promise<PreviewCheckModel | undefined> => {
  const { data } = await useRequest<PreviewCheckModel>(
    `${STUDIO_API_URL}/${API_VERSION}/${CONTEXT}/${groupId}/bank/preview-check`,
    {
      method: ApiMethod.Put,
      headers: { ...generateHeader('X-Lang', 'X-Nation') }
    }
  );

  return data;
};

// fetch provinces
export const fetchProvincesApi = async (): Promise<ProvincesResponse | undefined> => {
  const { data } = await useRequest<ProvincesResponse>(
    'dev/application_no/STATIC/new-studio-mock-data/group/provinces.json',
    {
      headers: {
        ...generateHeader(
          'X-Nation',
          'X-Timezone',
          'X-Utc-Offset',
          'X-Lang',
          'X-Client-Lang',
          'X-UUID',
          'caller-id'
        ),
        'Content-Type': 'application/json;charset=UTF-8'
      },
      baseURL: 'https://static-resource-dev.onstove.com'
    }
  );

  return data;
};

// fetch districts
export const fetchDistrictsApi = async (): Promise<DistrictsResponse | undefined> => {
  const { data } = await useRequest<DistrictsResponse>(
    'dev/application_no/STATIC/new-studio-mock-data/group/districts.json',
    {
      headers: {
        ...generateHeader(
          'X-Nation',
          'X-Timezone',
          'X-Utc-Offset',
          'X-Lang',
          'X-Client-Lang',
          'X-UUID',
          'caller-id'
        ),
        'Content-Type': 'application/json;charset=UTF-8'
      },
      baseURL: 'https://static-resource-dev.onstove.com'
    }
  );

  return data;
};
