import type { ResponseNormalization } from 'seed-core';

import { DEFAULT_COUNTRY } from '@/constants/cookie.const';
import { EU_NATIONAL_LIST } from '@/constants/national-list.const';
import { BusinessReviewStatus } from '@/enums/business.enum';
import { Confirmation } from '@/enums/common.enum';
import type {
  BankApproveModel,
  BankInfoModel,
  BankListModel
} from '@/types/bank-info/bank-model.type';
import type { BankInfoRequest } from '@/types/bank-info/bank-request.type';
import type {
  BankInfoResponse,
  BankListResponse,
  KrBankListResponse,
  KrBanks
} from '@/types/bank-info/bank-response.type';
import type { BusinessBankContract } from '@/types/common/file.type';
import type { FormOption } from '@/types/common/form.type';

export const fetchBankApprovalMapper = (res: BankInfoResponse): BankApproveModel => {
  const { reviewInfo, approvedBankInfo } = res;

  const data: BankApproveModel = {
    status: reviewInfo ? reviewInfo.status : '',
    nation: approvedBankInfo?.bankCountry ?? '',
    bankName: approvedBankInfo?.bankName ?? '',
    accountHolder: approvedBankInfo?.accountName ?? '',
    accountNumber: approvedBankInfo?.accountNo,
    swiftCode: approvedBankInfo?.swiftCode ?? '',
    rejectReason: reviewInfo?.rejectReason ?? '',
    checkedYn: reviewInfo ? reviewInfo.checkedYn : Confirmation.NO
  };
  return data;
};

export const fetchBankInfoMapper = (res: BankInfoResponse): BankInfoModel => {
  const { bankInfo, approvedBankInfo, reviewInfo } = res;
  const {
    bankCountry,
    bankName,
    bankCode,
    accountNo,
    accountName,
    contracts,
    swiftCode = '',
    routingNo = '',
    branchName = '',
    firstName = '',
    lastName = '',
    ibanCode = '',
    ccCode = '',
    bankBranch = '',
    bankAddress = ''
  } = bankInfo;

  const {
    bankCountry: approvedBankCountry,
    bankName: approvedBankName,
    bankCode: approvedBankCode,
    accountNo: approvedAccountNo,
    accountName: approvedAccountName,
    contracts: approvedContracts = [],
    swiftCode: approvedSwiftCode = '',
    routingNo: approvedRoutingNo = '',
    branchName: approvedBranchName = '',
    firstName: approvedFirstName = '',
    lastName: approvedLastName = '',
    ibanCode: approvedIbanCode = '',
    ccCode: approvedCcCode = '',
    bankBranch: approvedBankBranch = '',
    bankAddress: approvedBankAddress = ''
  } = approvedBankInfo ?? {};

  const bankBook = [...contracts];
  const bankBookName = bankBook.map(({ fileName }: BusinessBankContract) => fileName).join(', ');
  const approvedBankBook = [...approvedContracts];
  const approvedBankBookName = approvedBankBook.map(({ fileName }: BusinessBankContract) => fileName).join(', ');

  let account;

  if (reviewInfo?.status === BusinessReviewStatus.Request) {
    account = {
      bankCountry,
      bankName,
      bankCode,
      accountNo,
      accountHolder: accountName,
      bankBook,
      bankBookName,
      bankAddress,
      swiftCode,
      routingNo,
      branchName: branchName || bankBranch || '',
      firstName,
      lastName,
      ibanCode,
      ccCode
    };
  } else {
    account = {
      bankCountry: approvedBankCountry ?? bankCountry,
      bankName: approvedBankName ?? bankName,
      bankCode: approvedBankCode ?? bankCode,
      accountNo: approvedAccountNo ?? accountNo,
      accountHolder: approvedAccountName ?? accountName,
      bankBook: approvedBankBook.length ? approvedBankBook : bankBook,
      bankBookName: approvedBankBookName || bankBookName,
      bankAddress: approvedBankAddress || bankAddress,
      swiftCode: approvedSwiftCode || swiftCode,
      routingNo: approvedRoutingNo || routingNo,
      branchName: approvedBranchName || approvedBankBranch || branchName || bankBranch,
      firstName: approvedFirstName || firstName,
      lastName: approvedLastName || lastName,
      ibanCode: approvedIbanCode || ibanCode,
      ccCode: approvedCcCode || ccCode
    };
  }

  const data: BankInfoModel = {
    account
  };
  return data;
};

export const createBankInfoMapper = (res: BankInfoModel): BankInfoRequest => {
  const { account } = res;
  const {
    bankCountry,
    bankName,
    bankCode,
    accountNo,
    accountHolder: accountName,
    bankBook,
    bankAddress,
    swiftCode,
    routingNo,
    branchName,
    firstName,
    lastName,
    ibanCode,
    ccCode
  } = account;

  const isEUBank = EU_NATIONAL_LIST.includes(bankCountry);

  const data: BankInfoRequest = {
    bankCode: bankCode ?? '',
    bankName,
    accountNo,
    accountName: bankCountry === DEFAULT_COUNTRY ? accountName : '',
    bankCountry,
    routingNo,
    branchName,
    swiftCode,
    ibanCode: isEUBank ? ibanCode : undefined,
    ccCode: bankCountry === 'CA' ? ccCode : undefined,
    // status,
    contracts: bankBook,
    bankAddress,
    firstName: bankCountry !== DEFAULT_COUNTRY ? firstName : '',
    lastName: bankCountry !== DEFAULT_COUNTRY ? lastName : ''
  };
  return data;
};

export const fetchBankListMapper = (
  res: ResponseNormalization<BankListResponse>
): ResponseNormalization<BankListModel> => {
  const { contents, totalPages, totalElements } = res.data ?? {};

  const data: BankListModel = {
    contents: contents ?? [],
    totalPages: totalPages ?? 0,
    totalElements: totalElements ?? 0
  };

  return {
    ...res,
    data
  };
};

export const fetchKrBanksMapper = (res: KrBankListResponse): FormOption[] => {
  const { contents } = res;

  const data = contents.map(({ bankCode, bankName }: KrBanks) => {
    return {
      value: bankCode,
      label: bankName
    };
  });

  return data;
};

export const findKrBankName = (krBanks: FormOption[], bankCode: string): string => {
  const bank = krBanks.find(({ value }: FormOption) => value === bankCode);
  return bank ? bank.label : '';
};
