export const BUSINESS_BANK_STATUS = {
  REQUEST: 'REQUEST',
  REJECT: 'REJECT',
  APPROVE: 'APPROVE'
};

export const BUSINESS_ACTION_BTN = {
  REGISTER: 'register',
  EDIT: 'edit',
  VIEW: 'view',
  DELETE: 'delete',
  SAVING: 'saving'
};

export const MANAGER_TYPE = {
  RATING: 'RATING',
  CONTRACT: 'CONTRACT'
  // STAFF: 'STAFF'
} as const;

export type ManagerType = keyof typeof MANAGER_TYPE;

export const SUB_BIZ_INFO = {
  GAME_PRODUCTION: 'gameDevelopPublishRegistration',
  MAIL_ORDER: 'telecommunicationRegistration',
  KW_BIZ_REGIS_CER: 'koreaBusinessRegistration'
};

export const GAME_TYPE = {
  DEVELOPER: 'DEVELOPER',
  BROKERAGE: 'BROKERAGE',
  NONE: 'NONE'
};

export type GameType = keyof typeof GAME_TYPE;

export const BANK_TYPE = {
  BUSINESS_BANK_INFO: 'BUSINESS_BANK_INFO',
  INDIVIDUAL_BANK_INFO: 'INDIVIDUAL_BANK_INFO'
};

export const ACCOUNT_TYPE = {
  SAVING_ACCOUNT: 'SAVING_ACCOUNT',
  BUSINESS_ACCOUNT: 'BUSINESS_ACCOUNT',
  DEBIT: 'DEBIT',
  CREDIT: 'CREDIT'
};

export const BUSINESS_TYPE = {
  PERSONAL: 'PERSONAL',
  INDIVIDUAL_BUSINESS: 'INDIVIDUAL_BUSINESS', // Kr
  CORPORATION_BUSINESS: 'CORPORATION_BUSINESS' // KR and others
};

export type BusinessType = keyof typeof BUSINESS_TYPE;
