import type { ResponseNormalization } from 'seed-core';

import {
  BUSINESS_TYPE,
  MANAGER_TYPE,
  type ManagerType,
  SUB_BIZ_INFO
} from '@/constants/business-bank.const';
import { DEFAULT_NATION } from '@/constants/common.const';
import { DEFAULT_COUNTRY } from '@/constants/cookie.const';
import { COUNTRY_LIST } from '@/constants/national-list.const';
import { Confirmation, NationsAddress } from '@/enums/common.enum';
import type {
  BusinessApprovalModel,
  BusinessInfoModel,
  BusinessListModel,
  CompanyInfoModel,
  IndividualModel,
  ManagerInfoModel
} from '@/types/business-info/business-model.type';
import type { BusinessInfoRequest } from '@/types/business-info/business-request.type';
import type {
  BusinessInfo,
  BusinessInfoItem,
  BusinessListResponse,
  CompanyInfo,
  ManagerInfo,
  ReviewInfo
} from '@/types/business-info/business-response.type';
import type { CommonCountry } from '@/types/common/common.type';
import type { BusinessBankContract } from '@/types/common/file.type';

const personalBirthDayMapper = (year: number, month: number, day: number): string => {
  // yyyy-MM-dd
  const paddedMonth = month < 10 ? `0${month}` : month;
  const paddedDay = day < 10 ? `0${day}` : day;
  return `${year}-${paddedMonth}-${paddedDay}`;
};

export const fetchBusinessApprovalMapper = (approveCompanyInfo: BusinessInfoItem, reviewInfo?: ReviewInfo): BusinessApprovalModel => {
  const { businessInfo, businessType, country, companyName } =
    (approveCompanyInfo.companyInfo as CompanyInfo) ?? {};
  const { status, checkedYn } = (reviewInfo as ReviewInfo) ?? {};

  const ceoName = getCeoName(approveCompanyInfo.companyInfo);

  const { personalInfo, businessRegistrationNumber, koreaBusinessRegistrationNumber } =
    businessInfo;
  const data: BusinessApprovalModel = {
    status: status ?? '',
    nation: country,
    companyRegisNum:
      country === DEFAULT_NATION ? koreaBusinessRegistrationNumber : businessRegistrationNumber,
    businessType,
    ceoName,
    ceoBirth: personalInfo
      ? personalBirthDayMapper(
        personalInfo.birthYear,
        personalInfo.birthMonth,
        personalInfo.birthDay
      )
      : '',
    companyName,
    checkedYn,
    rejectReason: reviewInfo?.rejectReason ?? ''
  };

  return data;
};

export const fetchBusinessListMapper = (
  res: ResponseNormalization<BusinessListResponse>
): ResponseNormalization<BusinessListModel> => {
  const { contents, totalPages, totalElements } = res.data ?? {};

  const data: BusinessListModel = {
    contents: contents ?? [],
    totalPages: totalPages ?? 0,
    totalElements: totalElements ?? 0
  };

  return {
    ...res,
    data
  };
};

const getCallingCode = (countryCode: string): string => {
  return COUNTRY_LIST.find((country: CommonCountry) => country.code === countryCode)?.phoneCd ?? '';
};

const managerMapper = (managers?: ManagerInfo[], managerType?: ManagerType): ManagerInfo => {
  return (
    managers?.find((manager: ManagerInfo) => manager.managerType === managerType) ||
    ({} as ManagerInfo)
  );
};

const revertManagerMapper = (
  settInfo: ManagerInfoModel,
  staffInfo: ManagerInfoModel
): ManagerInfo[] => {
  const managers: ManagerInfo[] = [];
  if (settInfo && settInfo.name) {
    const settManager = createManager(settInfo, MANAGER_TYPE.CONTRACT);
    managers.push(settManager);
  }
  if (staffInfo && staffInfo.name) {
    const staffManager = createManager(staffInfo, MANAGER_TYPE.RATING);
    managers.push(staffManager);
  }
  return managers;
};

const createManager = (info: ManagerInfoModel, managerType: ManagerType): ManagerInfo => {
  return {
    managerType,
    managerName: info.name !== '' ? info.name : undefined,
    email: info.email ? info.email : undefined,
    phoneNumber: {
      countryCode: info.mobileCd ? info.mobileCd : undefined,
      countryCallingCode: info.mobileCd ? getCallingCode(info.mobileCd) : undefined,
      phoneNumber: info.mobileNo ? info.mobileNo : undefined
    }
  };
};

const getContractFileName = (contractNos: number[], contracts: BusinessBankContract[]): string => {
  let contractFileName = '';
  if (
    Array.isArray(contractNos) &&
    contractNos.length &&
    Array.isArray(contracts) &&
    contracts.length
  ) {
    const contract: BusinessBankContract | undefined = contracts.find(
      (contract: BusinessBankContract) => contract.contractNo === contractNos[0]
    );
    if (contract) {
      contractFileName = contract.fileName;
    }
  }
  return contractFileName;
};

export const businessInfoMapper = (
  bizInfo: BusinessInfo,
  companyInfo: CompanyInfo,
  contracts: BusinessBankContract[]
): CompanyInfoModel => {
  const {
    taxId,
    businessRegistrationNumber,
    businessRegistrationContractNo,
    koreaBusinessRegistrationNumber,
    koreaBusinessRegistrationContractNo,
    gameDevelopPublishRegistrationNumber,
    gameDevelopPublishRegistrationContractNo,
    telecommunicationRegistrationNumber,
    telecommunicationRegistrationContractNo,
    koreaCategory,
    koreaType
  } = bizInfo;

  const { companyName, phoneNumber, address, businessType } = companyInfo;
  const gameDevelopPublishRegistrationContractFileName = getContractFileName(
    gameDevelopPublishRegistrationContractNo || [],
    contracts
  );
  const telecommunicationRegistrationContractFileName = getContractFileName(
    telecommunicationRegistrationContractNo || [],
    contracts
  );
  const koreaBusinessRegistrationContractFileName = getContractFileName(
    koreaBusinessRegistrationContractNo || [],
    contracts
  );

  const license = businessType === BUSINESS_TYPE.PERSONAL ? Confirmation.NO : Confirmation.YES;

  const fullAddress = getFullAddress(companyInfo);
  const fullAddressDetail = getFullAddressDetail(companyInfo);
  const ceoName = getCeoName(companyInfo);

  return {
    name: companyName,
    ceoName,
    mobileCd: phoneNumber.countryCode,
    mobileNo: phoneNumber.phoneNumber,
    zipCode: address.zipCode,
    // TAIWAN: city - ward
    city: address.addressCity ?? '',
    ward: address.ward ?? '',
    // VN: district - province
    district: address.district ?? '',
    province: address.province ?? '',
    // US: street + building + city + ward
    streetName: address.streetName ?? '',
    buildingName: address.buildingName ?? '',
    cityName: address.addressCity ?? '',
    cityGroup: address.ward ?? '',
    // JP: address + fullAddress
    address: fullAddress,
    detailAddress: fullAddress,
    fullAddress: fullAddressDetail,
    registrationNo:
      companyInfo.country === DEFAULT_COUNTRY && koreaBusinessRegistrationNumber
        ? koreaBusinessRegistrationNumber
        : businessRegistrationNumber,
    businessRegistrationContractNo:
      license === Confirmation.YES
        ? (DEFAULT_COUNTRY &&
          koreaBusinessRegistrationContractNo &&
          koreaBusinessRegistrationContractNo.length > 0
          ? koreaBusinessRegistrationContractNo
          : businessRegistrationContractNo) ?? []
        : contracts && contracts[0] && [contracts[0].contractNo],

    koreaBusinessRegistrationNumber,
    koreaBusinessRegistrationContractNo,
    koreaBusinessRegistrationContract: koreaBusinessRegistrationContractFileName,
    gameDevelopPublishRegistrationNumber,
    gameDevelopPublishRegistrationContractNo,
    gameDevelopPublishRegistrationContract: gameDevelopPublishRegistrationContractFileName,
    telecommunicationRegistrationNumber,
    telecommunicationRegistrationContractNo,
    telecommunicationRegistrationContract: telecommunicationRegistrationContractFileName,

    taxId,
    type: koreaType ?? '',
    businessType,
    subBizDescription: koreaCategory ?? '',
    nation: address.country || (companyInfo.country ?? '')
  };
};

// export const checkBusinessRegistrationNumberMapper = (res: CheckBusinessRegistrationNumberResponse): CompanyInfoModel => {
//   const { businessInfo } = res;
//   const { address } = businessInfo;
//   const {
//     phoneNumber, addressCity, province,
//     streetName,
//     buildingName,
//     fullAddress,
//     fullAddressDetail,
//     countryCode
//   } = address;

//   return {
//     name: businessInfo.businessName,
//     ceoName: businessInfo.ceoName,
//     mobileCd: phoneNumber.countryCode,
//     mobileNo: phoneNumber.phoneNumber,
//     zipCode: businessInfo.businessRegistrationNumber,
//     city: addressCity,
//     province,
//     streetName,
//     buildingName,
//     address: fullAddress,
//     detailAddress: fullAddressDetail,
//     registrationNo: businessInfo.businessRegistrationNumber,
//     koreaBusinessRegistrationNumber: businessInfo.koreaBusinessRegistrationNumber,
//     gameDevelopPublishRegistrationNumber: '',
//     telecommunicationRegistrationNumber: '',
//     taxId: businessInfo.taxId,
//     type: businessInfo.businessType,
//     businessType: businessInfo.businessType,
//     subBizDescription: '',
//     nation: countryCode
//   };
// };

export const fetchBusinessRegistrationMapper = (res: BusinessInfoItem): BusinessInfoModel => {
  const { companyInfo, contracts, managers } = res;
  const {
    companyName,
    country,
    taxCountry,
    countryTaxEqualYn,
    phoneNumber,
    businessInfo,
    corporationType,
    email: emailCompany,
    businessType
  } = companyInfo;
  const {
    personalInfo,
    gameDevelopPublishRegistrationNumber,
    telecommunicationRegistrationNumber,
    koreaBusinessRegistrationNumber,
    businessRegistrationContractNo,
    koreaBusinessRegistrationContractNo
  } = businessInfo;
  const { countryCode: mobileCd, phoneNumber: mobileNo } = phoneNumber;

  const {
    managerName: settName,
    phoneNumber: settPhone,
    email: settMail
  } = managerMapper(managers, MANAGER_TYPE.CONTRACT);

  const {
    managerName: staffName,
    phoneNumber: staffPhone,
    email: staffMail
  } = managerMapper(managers, MANAGER_TYPE.RATING);

  const uploadResident = contracts ? [...contracts] : [];
  const registerContractNo =
    (DEFAULT_COUNTRY &&
    koreaBusinessRegistrationContractNo &&
    koreaBusinessRegistrationContractNo.length > 0
      ? koreaBusinessRegistrationContractNo
      : businessRegistrationContractNo) ?? [];
  const license = businessType === BUSINESS_TYPE.PERSONAL ? Confirmation.NO : Confirmation.YES;
  const businessRegistrationFileName =
    license === Confirmation.YES
      ? getContractFileName(registerContractNo || [], contracts)
      : contracts && contracts[0]?.fileName;
  const individual: IndividualModel = {
    license,
    nationality: country ?? '',
    countryTaxEqualYn: countryTaxEqualYn === Confirmation.YES,
    taxCountry: taxCountry ?? '',
    name: companyName,
    gender: personalInfo && personalInfo.gender ? personalInfo.gender.toUpperCase() : '',
    inputBirth:
      personalInfo &&
      personalInfo.birthDay !== 0 &&
      personalInfo.birthMonth !== 0 &&
      personalInfo.birthYear !== 0
        ? personalBirthDayMapper(
          personalInfo?.birthYear,
          personalInfo?.birthMonth,
          personalInfo?.birthDay
        )
        : '',
    birth:
      personalInfo &&
      personalInfo.birthDay !== 0 &&
      personalInfo.birthMonth !== 0 &&
      personalInfo.birthYear !== 0
        ? personalBirthDayMapper(
          personalInfo?.birthYear,
          personalInfo?.birthMonth,
          personalInfo?.birthDay
        )
        : '',
    mobileCd,
    mobileNo,
    uploadResidentName: businessRegistrationFileName,
    uploadResident,
    gameType: corporationType ?? '', // businessInfo.brokerType,
    subBizInfo: [
      gameDevelopPublishRegistrationNumber ? SUB_BIZ_INFO.GAME_PRODUCTION : '',
      telecommunicationRegistrationNumber ? SUB_BIZ_INFO.MAIL_ORDER : '',
      koreaBusinessRegistrationNumber ? SUB_BIZ_INFO.KW_BIZ_REGIS_CER : ''
    ], // businessInfo.subBizInfo,
    email: emailCompany,
    settInfo: {
      name: settName,
      mobileCd: settPhone?.countryCode ?? '',
      mobileNo: settPhone?.phoneNumber ?? '',
      email: settMail ?? ''
    },
    staffInfo: {
      name: staffName,
      mobileCd: staffPhone?.countryCode ?? '',
      mobileNo: staffPhone?.phoneNumber ?? '',
      email: staffMail ?? ''
    },
    businessInfo: businessInfoMapper(businessInfo, companyInfo, contracts)
  };

  const data: BusinessInfoModel = { individual };

  return data;
};

export const createBusinessInfoMapper = (res: BusinessInfoModel): BusinessInfoRequest => {
  const { individual } = res;
  const {
    birth,
    mobileCd,
    mobileNo,
    uploadResident,
    gameType,
    staffInfo,
    settInfo,
    businessInfo,
    gender,
    inputBirth,
    subBizInfo,
    nationality
  } = individual;

  if (!subBizInfo.includes(SUB_BIZ_INFO.GAME_PRODUCTION)) {
    businessInfo.gameDevelopPublishRegistrationNumber = '';
    businessInfo.gameDevelopPublishRegistrationContract = '';
    businessInfo.gameDevelopPublishRegistrationContractNo = [];
  }
  if (!subBizInfo.includes(SUB_BIZ_INFO.MAIL_ORDER)) {
    businessInfo.telecommunicationRegistrationNumber = '';
    businessInfo.telecommunicationRegistrationContract = '';
    businessInfo.telecommunicationRegistrationContractNo = [];
  }
  if (!subBizInfo.includes(SUB_BIZ_INFO.KW_BIZ_REGIS_CER)) {
    businessInfo.koreaBusinessRegistrationNumber = undefined;
    businessInfo.koreaBusinessRegistrationContract = '';
    businessInfo.koreaBusinessRegistrationContractNo = [];
  }

  const dateTimeInputInBusinessRegister = inputBirth ? new Date(inputBirth) : undefined;

  const managers = revertManagerMapper(settInfo, staffInfo);

  const contracts = [...uploadResident];

  const koreaBusinessRegistrationContractNo = businessInfo.koreaBusinessRegistrationContractNo
    ? [...businessInfo.koreaBusinessRegistrationContractNo]
    : [];
  const gameDevelopPublishRegistrationContractNo =
    businessInfo.gameDevelopPublishRegistrationContractNo
      ? [...businessInfo.gameDevelopPublishRegistrationContractNo]
      : [];
  const telecommunicationRegistrationContractNo =
    businessInfo.telecommunicationRegistrationContractNo
      ? [...businessInfo.telecommunicationRegistrationContractNo]
      : [];

  let fullAddress = businessInfo.address;
  if (businessInfo.nation !== DEFAULT_COUNTRY && businessInfo.nation !== NationsAddress.JAPAN && businessInfo.nation !== NationsAddress.TAIWAN && businessInfo.nation !== NationsAddress.VIETNAM) {
    fullAddress = undefined;
  }

  const address = {
    // TAIWAN: city - ward
    zipCode: businessInfo.zipCode,
    addressCity: businessInfo.cityName,
    ward: businessInfo.cityGroup,
    // VN: district - province
    district: businessInfo.district,
    province: businessInfo.province,
    // JP: address + fullAddress
    fullAddress,
    fullAddressDetail: businessInfo.fullAddress,
    country: businessInfo.nation,
    // Other countries
    streetName: businessInfo.streetName,
    buildingName: businessInfo.buildingName
  };

  const personalInfo =
    inputBirth && dateTimeInputInBusinessRegister
      ? {
        birthYear: new Date(dateTimeInputInBusinessRegister).getFullYear(),
        birthMonth: new Date(dateTimeInputInBusinessRegister).getMonth() + 1,
        birthDay: new Date(dateTimeInputInBusinessRegister).getDate(),
        gender
      }
      : birth
        ? {
          birthYear: new Date(birth).getFullYear(),
          birthMonth: new Date(birth).getMonth() + 1,
          birthDay: new Date(birth).getDate(),
          gender
        }
        : undefined;

  let businessType = BUSINESS_TYPE.PERSONAL;
  if (individual.license === Confirmation.YES) {
    businessType = businessInfo.businessType;
    if (businessInfo.nation === DEFAULT_COUNTRY) {
      address.fullAddress = businessInfo.detailAddress;
    } else {
      businessType = BUSINESS_TYPE.CORPORATION_BUSINESS;
    }
  }

  let companyName = individual.businessInfo.ceoName;
  if (businessType !== BUSINESS_TYPE.PERSONAL) {
    companyName = businessInfo.name;
  }

  const companyInfo: CompanyInfo = {
    companyName,
    businessType,
    representative: individual.businessInfo.ceoName || individual.name,
    corporationType: gameType,
    email: individual.email,
    country: businessType === BUSINESS_TYPE.PERSONAL ? nationality : businessInfo.nation,
    taxCountry: individual.countryTaxEqualYn
      ? businessType === BUSINESS_TYPE.PERSONAL
        ? nationality
        : businessInfo.nation
      : individual.taxCountry,
    phoneNumber: {
      countryCode: mobileCd,
      countryCallingCode: mobileCd ? getCallingCode(mobileCd) : undefined,
      phoneNumber: mobileNo
    },
    address,
    countryTaxEqualYn: individual.countryTaxEqualYn ? Confirmation.YES : Confirmation.NO,
    businessInfo: {
      personalInfo,
      koreaBusinessRegistrationContractNo:
        businessInfo.nation === DEFAULT_COUNTRY
          ? businessInfo.businessRegistrationContractNo
          : koreaBusinessRegistrationContractNo,
      koreaBusinessRegistrationNumber:
        businessInfo.nation === DEFAULT_COUNTRY
          ? businessInfo.registrationNo
          : businessInfo.koreaBusinessRegistrationNumber,
      businessRegistrationContractNo:
        businessInfo.nation !== DEFAULT_COUNTRY
          ? businessInfo.businessRegistrationContractNo
          : undefined,
      businessRegistrationNumber:
        businessInfo.nation !== DEFAULT_COUNTRY ? businessInfo.registrationNo : undefined,
      gameDevelopPublishRegistrationNumber: businessInfo.gameDevelopPublishRegistrationNumber,
      gameDevelopPublishRegistrationContractNo,
      telecommunicationRegistrationNumber: businessInfo.telecommunicationRegistrationNumber,
      telecommunicationRegistrationContractNo,
      taxId: businessInfo.taxId,
      koreaType: businessInfo.type,
      koreaCategory: businessInfo.subBizDescription
    }
  };

  const searchNo = businessInfo.searchNo;

  return { companyInfo, contracts, managers, searchNo };
};

/**
 * Get formatted full address based on business type and country
 *
 * For PERSONAL business:
 * - If fullAddress is null:
 *   + For KR/VN/TW/JP: returns "addressCity region"
 *   + For other countries: returns empty string
 * - If fullAddress is not null: returns fullAddress value
 *
 * For NON-PERSONAL business:
 * - If fullAddress is null:
 *   + For KR: returns "addressCity region, address1, address2" (commas only added for non-empty values)
 *   + For VN/TW/JP: returns "addressCity region"
 *   + For other countries: returns empty string
 * - If fullAddress is not null: returns fullAddress value
 *
 * Note: All null values are replaced with empty string
 */
const formatAddressForKorea = (address: CompanyInfo['address']): string => {
  const addressCity = address.addressCity === null ? '' : address.addressCity ?? '';
  const region = address.region === null ? '' : address.region ?? '';
  const address1 = address.address1 === null ? '' : address.address1 ?? '';
  const address2 = address.address2 === null ? '' : address.address2 ?? '';

  const baseAddress = `${addressCity} ${region}`.trim();
  const add1 = address1 ? `${baseAddress ? ', ' : ''}${address1}` : '';
  const add2 = address2 ? `${baseAddress || address1 ? ', ' : ''}${address2}` : '';

  return `${baseAddress}${add1}${add2}`.trim();
};

const formatBasicAddress = (address: CompanyInfo['address']): string => {
  const addressCity = address.addressCity === null ? '' : address.addressCity ?? '';
  const region = address.region === null ? '' : address.region ?? '';
  return `${addressCity} ${region}`.trim();
};

const isAsianCountry = (country: NationsAddress): boolean => {
  return [
    NationsAddress.KOREA,
    NationsAddress.VIETNAM,
    NationsAddress.TAIWAN,
    NationsAddress.JAPAN
  ].includes(country);
};

const getFullAddress = (companyInfo: CompanyInfo): string => {
  const { businessType, address } = companyInfo;
  const country =
    (address.country as NationsAddress) ?? (companyInfo.country as NationsAddress) ?? '';

  if (address.fullAddress !== null) {
    return address.fullAddress ?? '';
  }

  if (businessType === BUSINESS_TYPE.PERSONAL) {
    return isAsianCountry(country) ? formatBasicAddress(address) : '';
  }

  if (country === NationsAddress.KOREA) {
    return formatAddressForKorea(address);
  }

  if ([NationsAddress.VIETNAM, NationsAddress.TAIWAN, NationsAddress.JAPAN].includes(country)) {
    return formatBasicAddress(address);
  }

  return '';
};

/**
 * Get formatted full address detail based on business type and country
 *
 * For PERSONAL business:
 * - If fullAddressDetail is null:
 *   + For KR/JP/VN/TW: returns "address1, address2" (comma only added if address2 exists)
 *   + For other countries: returns "address2, address1, addressCity region" (commas only added for non-empty values)
 * - If fullAddressDetail is not null: returns fullAddressDetail value
 *
 * For NON-PERSONAL business:
 * - If fullAddressDetail is null:
 *   + For KR: returns empty string
 *   + For JP/VN/TW: returns "address1, address2" (comma only added if address2 exists)
 *   + For other countries: returns "address1, address2, addressCity region" (commas only added for non-empty values)
 * - If fullAddressDetail is not null: returns fullAddressDetail value
 *
 * Note: All null values are replaced with empty string
 */
const formatPersonalAsianDetail = (address: CompanyInfo['address']): string => {
  const address1 = address.address1 === null ? '' : address.address1 ?? '';
  const address2 = address.address2 === null ? '' : address.address2 ?? '';

  return `${address1}${address1 && address2 ? `, ${address2}` : address2}`.trim();
};

const formatPersonalOtherDetail = (address: CompanyInfo['address']): string => {
  const address1 = address.address1 === null ? '' : address.address1 ?? '';
  const address2 = address.address2 === null ? '' : address.address2 ?? '';
  const addressCity = address.addressCity === null ? '' : address.addressCity ?? '';
  const region = address.region === null ? '' : address.region ?? '';
  const cityRegion = `${addressCity} ${region}`.trim();

  let result = address2;
  if (address1) {
    result = result ? `${result}, ${address1}` : address1;
  }
  if (cityRegion) {
    result = result ? `${result}, ${cityRegion}` : cityRegion;
  }

  return result;
};

const formatNonPersonalDetail = (address: CompanyInfo['address']): string => {
  const address1 = address.address1 === null ? '' : address.address1 ?? '';
  const address2 = address.address2 === null ? '' : address.address2 ?? '';
  const addressCity = address.addressCity === null ? '' : address.addressCity ?? '';
  const region = address.region === null ? '' : address.region ?? '';
  const cityRegion = `${addressCity} ${region}`.trim();

  let result = address1;
  if (address2) {
    result = result ? `${result}, ${address2}` : address2;
  }
  if (cityRegion) {
    result = result ? `${result}, ${cityRegion}` : cityRegion;
  }

  return result;
};

const getFullAddressDetail = (companyInfo: CompanyInfo): string => {
  const { businessType, address } = companyInfo;
  const country =
    (address.country as NationsAddress) ?? (companyInfo.country as NationsAddress) ?? '';

  if (address.fullAddressDetail !== null) {
    return address.fullAddressDetail ?? '';
  }

  if (businessType === BUSINESS_TYPE.PERSONAL) {
    return isAsianCountry(country)
      ? formatPersonalAsianDetail(address)
      : formatPersonalOtherDetail(address);
  }

  if (country === NationsAddress.KOREA) {
    return '';
  }

  if ([NationsAddress.JAPAN, NationsAddress.VIETNAM, NationsAddress.TAIWAN].includes(country)) {
    return formatPersonalAsianDetail(address);
  }

  return formatNonPersonalDetail(address);
};

const getCeoName = (companyInfo: CompanyInfo): string => {
  if (companyInfo.businessType === BUSINESS_TYPE.PERSONAL) {
    if (companyInfo.representative && companyInfo.representative.trim()) {
      return companyInfo.representative;
    }

    return (companyInfo.companyName ?? '').trim() || '';
  }

  return companyInfo.representative ?? '';
};
